.btn:focus {
  outline: none;
  box-shadow: none;
}
.font-poppins {
  font-family: "Poppins";
}
.font-inter {
  font-family: "Inter";
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-20 {
  font-size: 20px;
}
.font-18 {
  font-size: 18px;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
